import * as React from "react"
import Layout from "../components/layout"
import { faSkype, faTelegram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Header from "../components/header"
// import banner1 from "../images/thankyou.svg"

const Thanks = () => (
  <Layout>
    <Header />
    <section className="banner thanks">
      <div className="container">
        <div className="row table-content">
          <div className="col-lg-5 col-md-12 col-sm-12 col-12 left-side">
            <img width="658px" height="360px" src="https://coinsclone.mo.cloudinary.net/images/thankyou.svg" alt="banner" />
          </div>
          <div className="col-lg-7 col-md-12 col-sm-12 col-12">
            <div className="thanks-right">
              <h1 className="main-heading">Thank you for Showing
                Interest in us</h1>
              <h3 className="heading-h3">We will get back to you with our demo soon. Make sure you provide valid information!!</h3>
              <h4 className="heading-h4">For Instant Demo ping us directly here</h4>
              <div className="touch">
                <a href="https://web.whatsapp.com/send?phone=919500575285&amp;text=Hello,  Send me the demo of your products." rel="noreferrer" target="_blank" class="btnwtp">
                  <span>
                    <FontAwesomeIcon icon={faWhatsapp} />
                  </span>
                </a>
                <a href="skype:live:hello_20214?chat" rel="noreferrer" target="_blank" class="btnskype">
                  <span>
                    <FontAwesomeIcon icon={faSkype} />
                  </span>
                </a>
                <a href="https://t.me/Coinzclone" rel="noreferrer" target="_blank" class="btnteleg">
                  <span>
                    <FontAwesomeIcon icon={faTelegram} />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default Thanks
